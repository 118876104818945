.ReactTable .rt-thead .rt-th:last-child {
  text-align: left !important;
}
.ReactTable {
  font-size: 0.8rem !important;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  font-size: 0.8rem !important;
}
.MuiTable-root,
.MuiTableCell-head,
.MuiTableCell-root,
.makeStyles-tableHeadFontSize-592 {
  font-size: 0.8rem !important;
}
.MuiTableHead-root {
  font-size: 0.7rem !important;
}

/* MuiTableCell-root makeStyles-tableHeadCell-593 makeStyles-tableCell-594 makeStyles-tableHeadFontSize-592 MuiTableCell-head */
